<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 900px;">
    <div class="wrap" style="min-height: 900px;">
      <h2 class="subTitle1"><span>인원 수 제한없이 4자리 맞추면 </span>당첨!</h2>
      <div class="inner_flex">
        <div data-v-339c7226="" class="game-side m_none mt0" style="float: unset; min-width: 230px;">
          <div class="list">
            <router-link to="/event/lotto" class="active">로또</router-link>
            <router-link to="/event/chulsuk" >출석부</router-link>
            <router-link to="/event/roulette">룰렛</router-link>
          </div>
        </div>


        <div class="contents_wrap" style="min-height: 900px;">
          <div class="lott" v-if="loading">
            <ul>
              <li v-for="num in 45" :key="'lotto'+num">
                <label>
                  <input
                    type="checkbox"
                    :value="num" class="tt"
                    @click.prevent="selectNum(num)"
                    :style="{ 'background' : payload.selected.indexOf(num) >= 0 ? '#000': '#fff'}"
                >
                  <em style="background-color: #000;"
                  v-if="payload.selected.indexOf(num) >= 0"
                  >
                  </em>
                  <em v-else>
                    <span>{{num}}</span>
                  </em>
                </label>
              </li>
            </ul>
            <div class="etc">
              <div @click="autoPick"><p>자동선택</p><label><input type="checkbox" class="tt2"><em><span></span></em></label></div>
              <div @click="pickCancel"><p>취소</p><label><input type="checkbox" class="tt2"><em><span></span></em></label></div>
            </div>
            <div class="lot-button">
              <!-- <a @click="processPick"><img src="@/assets/img/etc/click.png"></a> -->
              <a @click="processPick"><button class="btn-application">응모하기</button></a>

            </div>
          </div>
          <div style="width: 100%; text-align: center;"  v-else>
            <Loading v-if="!loading"></Loading>
          </div>

          <div class="lot-result" v-if="loading">
            <dl>
              <dt>{{ wait?.index }}회</dt>
              <dd>{{ wait?.startedDate | formatDate('YYYY-MM-DD') }}</dd>
            </dl>
            <div class="num">
            </div>
            <div class="price">
              <span style="margin-right: 3px;">당첨금</span>
              <strong>{{ lottoSetting.hitAmount | makeComma }}</strong>
              <em>원</em>
            </div>
          </div>

          <table class="listTable02">
            <colgroup>
              <col width="120" />
              <col width="120" />
              <col width="" />
              <col width="124" />
            </colgroup>
            <tr>
              <th>번호</th>
              <th>배팅시간</th>
              <th>배팅번호</th>
              <th>닉네임</th>
            </tr>
            <tr v-for="(row, index) in lists" :key="'lottoList'+index">
              <td>{{ ((payload.page - 1) * payload.size) + (index+1) }}</td>
              <td>{{ row.createdAt | formatDate('YYYY-MM-DD HH:mm') }}</td>
              <td>
                <div class="bet-res">
                  <span
                      v-for="(row2, index2) in row.number.split(',')"
                      :key="'lottoListNumber' + index +index2"
                      style="margin-right: 5px;"
                      :class="{
                        'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                        'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                        'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                        'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                        'bg-40' : Number(row2) > 40,
                      }"
                  >
                    {{ row2 }}
                  </span>
                </div>
              </td>
              <td>{{ row.member.members_nickname }}</td>
            </tr>


          </table>
          <Pagination v-if="pagination" :req-data="reqData2" :payload="payload" :pagination="pagination"></Pagination>
        </div>

        <div class="check_info_text">
          <div>
            <h3>로또 이벤트 참여하는 방법</h3>
            <p v-html="renderHtml(description)"></p>
          </div>
        </div>
      </div>
    </div>


  </section>
  <div v-else >
    <div class="header2">
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>로또</h2>
    </div>
    <div class="tabs1">
      <a @click="$router.push('/event/lotto')" class="active">로또</a>
      <a @click="$router.push('/event/chulsuk')">출석부</a>
      <a @click="$router.push('/event/roulette')">룰렛</a>
    </div>
    <div class="event">
      <div class="check_info_text">
        <p v-html="renderHtml(description)"></p>
      </div>

      <div style="text-align: center">
        <Loading v-if="!loading"></Loading>
      </div>

      <div class="lott" v-if="loading">
        <ul>
          <li v-for="num in 45" :key="'lotto'+num">
            <label>
              <input
                  type="checkbox"
                  :value="num" class="tt"
                  @click.prevent="selectNum(num)"
                  :style="{ 'background' : payload.selected.indexOf(num) >= 0 ? '#000': '#fff'}"
              >
              <em style="background-color: #000;"
                  v-if="payload.selected.indexOf(num) >= 0"
              >
              </em>
              <em v-else>
                <span>{{num}}</span>
              </em>
            </label>
          </li>
        </ul>
        <div class="etc">
          <div @click="autoPick"><p>자동선택</p><label><input type="checkbox" class="tt2"><em><span></span></em></label></div>
          <div @click="pickCancel"><p>취소</p><label><input type="checkbox" class="tt2"><em><span></span></em></label></div>
        </div>
        <div class="lot-button">
          <!-- <a @click="processPick"><img src="@/assets/img/etc/click.png"></a> -->
          <a @click="processPick"><button class="btn-application">응모하기</button></a>
        </div>
      </div>
      <div class="lot-result" v-if="loading">
        <dl>
          <dt>{{ wait?.index  }} 회</dt>
          <dd>{{ wait.startedDate | formatDate('YYYY-MM-DD HH:mm') }}</dd>
        </dl>
        <!-- <div class="num">
          <span
              v-if="complete"
              v-for="(num, index) in complete.result.split(',')"
              :key="'lottoNumIndex' + index"
              :class="{
                'bg-1' : Number(num) <= 10 && Number(num) > 0,
                'bg-10' : Number(num) <= 20 && Number(num) > 10,
                'bg-20' : Number(num) <= 30 && Number(num) > 20,
                'bg-30' : Number(num) <= 40 && Number(num) > 30,
                'bg-40' : Number(num) > 40,
              }"
          >
            {{ num }}
          </span>
        </div> -->
        <div class="price">
          <span>당첨금</span>
          <strong>{{ lottoSetting.hitAmount | makeComma }}</strong>
          <em>원</em>
        </div>
      </div>

      <table class="listTable02" v-if="loading">
        <colgroup>
          <col width="120" />
          <col width="120" />
          <col width="" />
          <col width="124" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>배팅시간</th>
          <th>배팅번호</th>
          <th>닉네임</th>
        </tr>
        <tr v-for="(row, index ) in lists" :key="'lottoList'+index">
          <td>{{ ((payload.page - 1) * payload.size) + (index+1) }}</td>
          <td>{{ row.createdAt | formatDate('YYYY-MM-DD') }}</td>
          <td>
            <div class="bet-res">
              <span
                  v-for="(row2, index2) in row.number.split(',')"
                  :key="'lottoListNumber' + index +index2"
                  style="margin-right: 5px;"
                  :class="{
                    'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                    'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                    'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                    'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                    'bg-40' : Number(row2) > 40,
                  }"
              >
                {{ row2 }}
              </span>
            </div>
          </td>
          <td>{{ row.member.members_nickname }}</td>
        </tr>
      </table>

    </div>

    <MPagination v-if="pagination" :req-data="reqData2" :payload="payload" :pagination="pagination"></MPagination>
  </div>
</template>

<script>
import Loading from '../../components/Loading'
import Pagination from '@/components/Pagination02'
import MPagination from '@/components/MPagination'

export default {
  name: "EventLotto",
  components: {
    Loading,
    Pagination,
    MPagination
  },
  data: function(){
    return {
      type: 'lotto',
      loading: false,
      lists: [],
      complete: null,
      wait: null,
      pagination: null,
      payload: {
        selected: [],
        lottoIndex: null,
        page: 1,
        size: 30
      },
    }
  },
  mounted: function() {
    this.reqData()
  },
  watch: {
    'payload.selected': function(val){
      if (val.length > 6) {
        alert('번호는 6개까지 선택가능합니다.')
        this.payload.selected.pop();
        return;
      }
    }
  },
  computed: {
    lottoSetting: function(){
      return this.$store.getters['LOTTO_SETTING']
    },
    description: function(){
      return this.lottoSetting.description;
    },
  },
  methods: {
    reqData: function(){
      this.loading = false;
      return this.$store.dispatch('GET_LOTTO_LIST',  {payload: this.payload})
          .then(data => {
            const result = data.data;
            this.loading = true;
            this.lists = result.list;
            this.pagination = result.pagination;
            this.complete = result.completeLotto;
            this.wait = result.waitLotto;
            this.payload.lottoIndex = this.wait.index;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    reqData2: function(){
      return this.$store.dispatch('GET_LOTTO_LIST',  {payload: this.payload})
          .then(data => {
            const result = data.data;
            this.lists = result.list;
            this.pagination = result.pagination;
            this.complete = result.completeLotto;
            this.wait = result.waitLotto;
            this.payload.lottoIndex = this.wait.index;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    autoPick: function(e){
      e.preventDefault();
      this.payload.selected = [];

      let t = true;
      while (t) {
        let randNum = Math.floor((Math.random() * 45) + 1);

        if (this.payload.selected.length > 5) {
          break;
        }

        if (this.payload.selected.indexOf(randNum) < 0) {
          this.payload.selected.push(randNum);
        }

        if (this.payload.selected.length > 5) {
          break;
        }
      }
    },
    pickCancel: function(e){
      e.preventDefault();
      this.payload.selected = []
    },
    processPick: function(){
      if (this.payload.selected.length < 6) return alert('6개를 선택후 응모해주세요');
      return this.$store.dispatch('PROCESS_LOTTO', { payload: this.payload })
          .then(data => {
            const result = data.data;
            this.payload.selected = []
            this.reqData2()
            alert(result.msg)
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    selectNum: function(num){
      console.log(num)
      if (this.payload.selected.length > 6) {
        return;
      }
      const index = this.payload.selected.indexOf(num);
      if (index >= 0){
        this.payload.selected.splice(index, 1);
      } else {
        this.payload.selected.push(num);
      }
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
  }
}
</script>

<style scoped>
.btn-application {
  background-color: #f00;
  color: #fff;
  border: 1px solid #f00;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  width: 90px;
  font-size: 15px;
}
</style>
